<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t('app.general.detail_of') }} {{ $t('app.jornadas.single') }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-subheader>{{ $t('app.general.information') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.torneo.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.torneos.single') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.equipo_local.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.local_team') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.equipo_visitante.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.visiting_team') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.cancha.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.canchas.single') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.descripcion }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.description') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fecha }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.date') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.hora }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.time') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.activo ? $t('app.general.yes') : $t('app.general.no') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.active') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'JornadasDetail',
    data () {
      return {
        dialog: false,
        item: false,
      }
    },
    mounted() {
      EventBus.$on('jornadas-detail', (item) => {
        this.item = item
        this.dialog = true
      })
    },
  }
</script>

<style scoped>

</style>
